<template>
  <div class="page polls">
    <div class="page-header">
      <div class="page-title">
        <h1>My votes</h1>
        <span>You have voted in <b>{{ totalPolls }}</b> poll{{ totalPolls > 1 ? 's' : '' }}</span>
      </div>
      <FilterSelector class="filter" v-model="filter" />
    </div>
    <div class="items">
      <PollItem :poll="poll" :user-id="user ? user.id : fingerprint" :is-admin="user && user.admin" v-for="poll in filteredPolls" :key="poll.id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  collection, getDocs, limit, onSnapshot, orderBy, query, where,
} from 'firebase/firestore';
import { db } from '../../api';
import PollItem from '../../components/PollItem.vue';
import { getFingerprint } from '../../utils/fingerprint';
import FilterSelector from '../../components/FilterSelector.vue';

export default {
  name: 'ProfileVotes',
  computed: {
    ...mapState(['user', 'authUser']),
    fingerprint() {
      return getFingerprint();
    },
    filteredPolls() {
      return this.polls.filter((poll) => {
        const ended = poll.ended_at && poll.ended_at.toDate() < new Date();
        switch (this.filter) {
          case 'active':
            return !ended;
          case 'inactive':
            return ended;
          default:
            return true;
        }
      });
    },
  },
  components: {
    PollItem,
    FilterSelector,
  },
  data() {
    return {
      loading: false,
      polls: [],
      totalPolls: 0,
      filter: 'none',
    };
  },
  mounted() {
    this.pollsRef = onSnapshot(query(collection(db, 'votes'), where('userId', '==', this.user.id), orderBy('created', 'desc'), limit(50)), async (snap) => {
      const ids = new Set();
      snap.docs.map((d) => ids.add(d.get('pollId')));
      this.totalPolls = ids.size;
      if (!ids.size) {
        return;
      }
      const docs = await getDocs(query(collection(db, 'polls'), where('id', 'in', [...ids].slice(0, 10)), orderBy('created', 'desc')));
      this.polls = docs.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          created: data.created.toDate(),
          updated: data.updated.toDate(),
        };
      });
    });
  },
  beforeDestroy() {
    if (this.pollsRef) {
      this.pollsRef();
    }
  },
};
</script>

<style scoped>
.page {
  width: 1024px;
  max-width: 100%;
  margin: auto;
}

.page-header {
  position: relative;
  padding: 0 32px;
}

.page-header .filter {
  position: absolute;
  top: 4px;
  right: 48px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 64px 32px;
  padding: 0 32px;
}

.items > * {
  width: calc(50% - 16px);
}

@media screen and (max-width: 900px) {
  .page-header {
    text-align: right;
    margin-bottom: 16px;
  }
  .page-header .filter {
    position: relative;
    top: auto;
    right: auto;
  }
  .page-title {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 800px) {
  .items {
    gap: 48px;
    padding: 0 32px;
  }
  .items > * {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .page-header,
  .items {
    padding: 0;
  }
}
</style>
